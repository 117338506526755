.header {
    background: $gray;
    width: 100%;
    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;
        @include respond-to(sm){
            flex-wrap: wrap;
            justify-content: center;
            padding: 10px 0;
        }
    }
    form{
        @include respond-to(sm){
            margin-bottom: 10px;
        }
    }
    &__info{
        display: flex;
        flex-direction: row;
        .phone{
            margin-right: 30px;
        }
        .mail a{
            color: $black;
        }
    }
}