*, body {
  font-family: 'Open Sans', sans-serif; }

.custom-object-fit {
  position: relative;
  background-size: cover;
  background-position: center center; }
  .custom-object-fit img {
    opacity: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.header {
  background: #ececec;
  width: 100%; }
  .header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px; }
    @media (max-width: 767px) {
      .header__wrapper {
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px 0; } }
  @media (max-width: 767px) {
    .header form {
      margin-bottom: 10px; } }
  .header__info {
    display: flex;
    flex-direction: row; }
    .header__info .phone {
      margin-right: 30px; }
    .header__info .mail a {
      color: #000000; }

.footer {
  border-top: 1px solid #eee;
  text-align: center;
  padding: 15px 0;
  font-size: 14px;
  margin-top: 30px;
  color: #7e7e7e; }
  .footer img {
    height: 50px;
    display: block;
    margin: 0 auto; }

.section-title {
  font-size: 24px;
  color: #000000;
  font-weight: 700;
  text-align: center;
  padding: 20px 0; }

.nav-tabs .nav-item {
  background: #ececec; }
  @media (max-width: 991px) {
    .nav-tabs .nav-item {
      flex: 1;
      text-align: center; } }
  @media (max-width: 767px) {
    .nav-tabs .nav-item {
      flex-wrap: wrap;
      flex-basis: 50%; } }
  .nav-tabs .nav-item .nav-link {
    font-weight: 700;
    color: #000000;
    border: 1px solid #ccc; }
    .nav-tabs .nav-item .nav-link.active {
      background: #4e9944;
      color: #ffffff; }

.content-tab {
  margin-top: 30px; }

.image-tab {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 300px;
  overflow: hidden; }
  .image-tab img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.navbar {
  flex-direction: column; }
  @media (max-width: 991px) {
    .navbar {
      flex-direction: row; } }
  @media (max-width: 991px) {
    .navbar-brand img {
      height: 70px;
      margin-top: 10px; } }
  @media (max-width: 991px) {
    .navbar-brand img {
      height: 50px; } }
  .navbar .navbar-nav {
    text-align: center; }
  .navbar .nav-item {
    padding: 0 15px; }
  .navbar .nav-link {
    text-transform: uppercase;
    font-weight: 700;
    color: #000000; }
    .navbar .nav-link:hover {
      color: #4e9944; }
  .navbar .navbar-toggler:hover, .navbar .navbar-toggler:focus, .navbar .navbar-toggler:active {
    outline: 0; }
  .navbar .fa-bars {
    font-size: 30px;
    color: #4e9944; }

.background-box {
  height: 500px;
  width: 100%;
  overflow: hidden; }
  @media (max-width: 991px) {
    .background-box {
      height: 300px; } }
  @media (max-width: 767px) {
    .background-box {
      height: 200px; } }
  .background-box img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.main-text {
  font-size: 40px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #4e9944;
  padding: 30px 0;
  display: inline-block; }
  @media (max-width: 991px) {
    .main-text {
      font-size: 30px; } }
  @media (max-width: 767px) {
    .main-text {
      font-size: 22px; } }
  .main-text span {
    display: block;
    font-weight: 700;
    color: #4e9944; }

.about-us {
  background: #4e9944;
  text-align: center; }
  .about-us__box {
    padding: 15px 0; }
    .about-us__box .image {
      height: 60px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center; }
    .about-us__box h3 {
      font-weight: 700;
      color: #ffffff;
      font-size: 32px; }
      @media (max-width: 767px) {
        .about-us__box h3 {
          font-size: 26px; } }
    .about-us__box p {
      color: #ffffff;
      font-size: 16px; }
      @media (max-width: 991px) {
        .about-us__box p {
          padding: 0 50px; } }
      @media (max-width: 767px) {
        .about-us__box p {
          padding: 0; } }
  .about-us__image {
    height: 350px;
    overflow: hidden; }
    @media (max-width: 767px) {
      .about-us__image {
        height: 250px; } }
    .about-us__image img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .about-us__text-box {
    text-align: center;
    margin-top: 30px; }

.star-box {
  padding: 0 0 15px; }

.project-box {
  height: 200px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px; }
  .project-box__front {
    position: relative;
    height: 100%;
    width: 100%; }
    .project-box__front .project-img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .project-box__front:hover .project-box__back {
      opacity: .8;
      transition: all 0.3s ease; }
  .project-box__back {
    opacity: 0;
    background: #193416;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #ffffff;
    font-size: 14px;
    padding: 0 15px; }
    .project-box__back p {
      color: #ffffff; }
    .project-box__back:hover {
      text-decoration: none; }
    .project-box__back img {
      margin-bottom: 15px; }

.slick-carousel {
  height: 350px;
  overflow: hidden;
  padding: 30px 0;
  color: #ffffff; }
  @media (max-width: 1199px) {
    .slick-carousel {
      height: auto; } }
  @media (max-width: 575px) {
    .slick-carousel {
      min-height: 450px; } }
  .slick-carousel__img {
    position: relative;
    width: 100%;
    height: 350px; }
    @media (max-width: 1199px) {
      .slick-carousel__img {
        height: auto; } }
    @media (max-width: 575px) {
      .slick-carousel__img {
        min-height: 450px; } }
    .slick-carousel__img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .slick-carousel__img:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #193416;
      opacity: 0.9; }
  .slick-carousel-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 1140px;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 1199px) {
      .slick-carousel-box {
        width: 80%; } }
    @media (max-width: 767px) {
      .slick-carousel-box {
        width: 100%; } }
  .slick-carousel__opinion {
    font-weight: 700;
    font-size: 18px; }
  .slick-carousel__text {
    font-style: italic; }
    @media (max-width: 767px) {
      .slick-carousel__text {
        font-size: 13px;
        padding: 0 15px; } }
  .slick-carousel__author {
    font-weight: 700; }

.slick-prev,
.slick-next {
  font-size: 0;
  position: absolute;
  top: 20px;
  color: #000000;
  border: 0;
  background: none;
  z-index: 99; }
  .slick-prev:focus,
  .slick-next:focus {
    outline: 0; }

.slick-prev {
  left: 150px;
  top: 40px; }
  @media (max-width: 1199px) {
    .slick-prev {
      left: 15px; } }

.slick-prev:after {
  content: '\f060';
  font-family: "Font Awesome 5 Free";
  font-size: 40px;
  font-weight: 900; }
  @media (max-width: 767px) {
    .slick-prev:after {
      font-size: 25px; } }

.slick-next {
  right: 150px;
  text-align: right;
  top: 40px; }
  @media (max-width: 1199px) {
    .slick-next {
      right: 15px; } }

.slick-next:after {
  content: '\f061';
  font-family: "Font Awesome 5 Free";
  font-size: 40px;
  font-weight: 900; }
  @media (max-width: 767px) {
    .slick-next:after {
      font-size: 25px; } }

.slick-prev:hover:after,
.slick-next:hover:after {
  color: #7e7e7e; }

.map-image {
  width: 100%;
  height: 300px;
  overflow: hidden; }
  @media (max-width: 991px) {
    .map-image {
      height: 200px; } }
  .map-image img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.contact-info {
  margin-top: 30px; }
  .contact-info a {
    color: #000000; }
  @media (max-width: 991px) {
    .contact-info {
      display: flex;
      width: 100%;
      font-size: 14px;
      justify-content: space-between; } }
  @media (max-width: 767px) {
    .contact-info {
      flex-wrap: wrap; }
      .contact-info p {
        flex-basis: 50%; } }
  @media (max-width: 575px) {
    .contact-info {
      flex-wrap: wrap; }
      .contact-info p {
        flex-basis: 100%; } }

.contact-form {
  margin-top: 30px; }
  .contact-form__input {
    width: 100%;
    line-height: 50px;
    font-size: 16px;
    padding: 0 15px; }
    .contact-form__input:focus {
      outline: 0; }
    @media (max-width: 767px) {
      .contact-form__input {
        margin-bottom: 15px;
        font-size: 14px; } }
  .contact-form__textarea {
    height: 120px;
    width: 100%;
    margin-top: 30px;
    padding: 15px; }
    .contact-form__textarea:focus {
      outline: 0; }
    @media (max-width: 767px) {
      .contact-form__textarea {
        margin-top: 0;
        font-size: 14px; } }
  .contact-form__button {
    background: #4e9944;
    color: #ffffff;
    border: 0;
    padding: 8px 50px;
    font-size: 14px;
    float: right;
    margin-top: 15px;
    transition: all 0.3s ease; }
    .contact-form__button:hover {
      background: #193416; }
    @media (max-width: 767px) {
      .contact-form__button {
        float: none;
        margin-top: 15px;
        display: block;
        margin: 15px auto 0; } }
  .contact-form ::-webkit-input-placeholder {
    color: #000000; }
    @media (max-width: 767px) {
      .contact-form ::-webkit-input-placeholder {
        font-size: 14px; } }
  .contact-form ::-moz-placeholder {
    color: #000000; }
    @media (max-width: 767px) {
      .contact-form ::-moz-placeholder {
        font-size: 14px; } }
  .contact-form :-ms-input-placeholder {
    color: #000000; }
    @media (max-width: 767px) {
      .contact-form :-ms-input-placeholder {
        font-size: 14px; } }
  .contact-form :-moz-placeholder {
    color: #000000; }
    @media (max-width: 767px) {
      .contact-form :-moz-placeholder {
        font-size: 14px; } }
  .contact-form #checkbox {
    display: none; }
  .contact-form label {
    display: flex;
    font-size: 12px;
    margin-top: 15px; }
  .contact-form input[type=checkbox] + label span {
    border: 1px solid #e0e0e0;
    width: 30px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px; }
    .contact-form input[type=checkbox] + label span:after {
      content: '';
      width: 20px;
      height: 20px;
      background: #4e9944;
      display: inline-block;
      border-radius: 3px;
      margin: 4px 20px 4px 4px;
      opacity: 0;
      transition: opacity .2s ease-in-out; }
  .contact-form input[type=checkbox]:checked + label span::after {
    opacity: 1; }
