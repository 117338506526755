// COLORS
$black: #000000;
$white: #ffffff;
$gray: #ececec;
$green: #4e9944;
$dark-green: #193416;
$grey: #7e7e7e;

//RWD
$breakpoints: (
        'xs': (max-width: 575px),
        'sm': (max-width: 767px),
        'md': (max-width: 991px),
        'lg': (max-width: 1199px),
        'xl': (min-width: 1200px),
);