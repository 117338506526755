.background-box{
    height: 500px;
    width: 100%;
    overflow: hidden;
    @include respond-to(md){
        height: 300px;
    }
    @include respond-to(sm){
        height: 200px;
    }
    img{
        @include objectfit;
    }
}

.main-text{
    font-size: 40px;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid $green;
    padding: 30px 0;
    display: inline-block;
    @include respond-to(md){
        font-size: 30px;
    }
    @include respond-to(sm){
        font-size: 22px;
    }
    span{
        display: block;
        font-weight: 700;
        color: $green;
    }
}

.about-us{
    background: $green;
    text-align: center;
    &__box{
        padding: 15px 0;
        .image{
           height: 60px;
           display: flex;
           width: 100%;
           align-items: center;
           justify-content: center;
        }
        h3{
            font-weight: 700;
            color: $white;
            font-size: 32px;
            @include respond-to(sm){
                font-size: 26px;
            }
        }
        p{
            color: $white;
            font-size: 16px;
            @include respond-to(md){
                padding: 0 50px;
            }
            @include respond-to(sm){
                padding: 0;
            }
        }
    }
    &__image{
        height: 350px;
        overflow: hidden;
        @include respond-to(sm){
            height: 250px;
        }
        img{
            @include objectfit;
        }
    }
    &__text-box{
        text-align: center;
        margin-top: 30px;
    }
}

.star-box{
    padding: 0 0 15px;
}

.project-box{
    height: 200px;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;
    &__front{
        position: relative;
        height: 100%;
        width: 100%;
        .project-img{
            @include objectfit;
        }
        &:hover{
            .project-box__back{
                opacity: .8;
                @include transition;
            }
        }
    }
    &__back{
        opacity: 0;
        background: $dark-green;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: $white;
        font-size: 14px;
        padding: 0 15px;
        p{
            color: $white;
        }
        &:hover{
            text-decoration: none;
        }
        img{
            margin-bottom: 15px;
        }
    }
}

.slick-carousel{
    height: 350px;
    overflow: hidden;
    padding: 30px 0;
    color: $white;
    @include respond-to(lg){
        height: auto;
    }
    @include respond-to(xs){
        min-height: 450px;
    }
    &__img{
        position: relative;
        width: 100%;
        height: 350px;
        @include respond-to(lg){
            height: auto;
        }
        @include respond-to(xs){
            min-height: 450px;
        }
        img{
            @include objectfit;
        }
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: $dark-green;
            opacity: 0.9;
        }
    }
    &-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 1140px;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include respond-to(lg){
            width: 80%;
        }
        @include respond-to(sm){
            width: 100%;
        }
    }
    &__opinion{
        font-weight: 700;
        font-size: 18px;
    }
    &__text{
        font-style: italic;
        @include respond-to(sm){
           font-size: 13px;
           padding: 0 15px;
        }
    }
    &__author{
        font-weight: 700;
    }
}

.slick-prev,
.slick-next {
    font-size: 0;
    position: absolute;
    top: 20px;
    color: $black;
    border: 0;
    background: none;
    z-index: 99;
    &:focus{
        outline: 0;
    }
}

.slick-prev {
    left: 150px;
    top: 40px;
    @include respond-to(lg){
        left: 15px;
    }
}

.slick-prev:after {
    content: '\f060';
    font-family: "Font Awesome 5 Free";
    font-size: 40px;
    font-weight: 900;
    @include respond-to(sm){
        font-size: 25px;
     }
}

.slick-next {
   right: 150px;
   text-align: right;
   top: 40px;
   @include respond-to(lg){
      right: 15px;
   }
}

.slick-next:after {
   content: '\f061';
   font-family: "Font Awesome 5 Free";
   font-size: 40px;
   font-weight: 900;
   @include respond-to(sm){
    font-size: 25px;
 }
}

.slick-prev:hover:after,
.slick-next:hover:after {
    color: #7e7e7e;
}

.map-image{
    width: 100%;
    height: 300px;
    overflow: hidden;
    @include respond-to(md){
        height: 200px;
    }
    img{
        @include objectfit;
    }
}
.contact-info{
    margin-top: 30px;
    a{
        color: $black;
    }
    @include respond-to(md){
        display: flex;
        width: 100%;
        font-size: 14px;
        justify-content: space-between
    }
    @include respond-to(sm){
        flex-wrap: wrap;
        p{
            flex-basis: 50%;
        }
    }
    @include respond-to(xs){
        flex-wrap: wrap;
        p{
            flex-basis: 100%;
        }
    }
}
.contact-form{
    margin-top: 30px;
    &__input{
        width: 100%;
        line-height: 50px;
        font-size: 16px;
        padding: 0 15px;
        &:focus{
            outline: 0;
        }
        @include respond-to(sm){
            margin-bottom: 15px;
            font-size: 14px;
        }
    }
    &__textarea{
        height: 120px;
        width: 100%;
        margin-top: 30px;
        padding: 15px;
        &:focus{
            outline: 0;
        }
        @include respond-to(sm){
            margin-top: 0;
            font-size: 14px;
        }
    }
    &__button{
        background: $green;
        color: $white;
        border: 0;
        padding: 8px 50px;
        font-size: 14px;
        float: right;
        margin-top: 15px;
        @include transition;
        &:hover{
            background: $dark-green;
        }
        @include respond-to(sm){
            float: none;
            margin-top: 15px;
            display: block;
            margin: 15px auto 0;
        }
    }
    ::-webkit-input-placeholder {
        color: $black;
        @include respond-to(sm){
            font-size: 14px;
        }
    }
    ::-moz-placeholder {
        color: $black;
        @include respond-to(sm){
            font-size: 14px;
        }
    }
    :-ms-input-placeholder { 
        color: $black;
        @include respond-to(sm){
            font-size: 14px;
        }
    }
    :-moz-placeholder { 
        color: $black;
        @include respond-to(sm){
            font-size: 14px;
        }
    }
    #checkbox{
        display: none;
    }
    label{
        display: flex;
        font-size: 12px;
        margin-top: 15px;
    }

    input[type=checkbox] + label span {
        border: 1px solid #e0e0e0;
        width: 30px;
        height: 30px;
        display: inline-block;
        cursor: pointer;
        margin-right: 5px;
        &:after{
            content: '';
            width: 20px;
            height: 20px;
            background: $green;
            display: inline-block;
            border-radius: 3px;
            margin: 4px 20px 4px 4px;
            opacity: 0;
            transition: opacity .2s ease-in-out;
        }
    }
    input[type=checkbox]:checked+label span::after{
        opacity: 1;
    }
}