.footer{
    border-top: 1px solid #eee;
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
    margin-top: 30px;
    color: $grey;
    img{
        height: 50px;
        display: block;
        margin: 0 auto;
    }
}