.nav-tabs{
    .nav-item{
        background: $gray;
        @include respond-to(md){
            flex: 1;
            text-align: center;
        }
        @include respond-to(sm){
            flex-wrap: wrap;
            flex-basis: 50%;
        }
        .nav-link{
            font-weight: 700;
            color: $black;
            border: 1px solid #ccc;
            &.active{
                background: $green;
                color: $white;
            }
        }
    }
}

.content-tab{
    margin-top: 30px;
}
.image-tab{
    margin-top: 30px;
    margin-bottom: 30px;
    height: 300px;
    overflow: hidden;
    img{
        @include objectfit;
    }
}