.navbar{
    flex-direction: column;
    @include respond-to(md){
        flex-direction: row;
    }
    &-brand{
        img {
            @include respond-to(md){
                height: 70px;    
                margin-top: 10px;
            }
            @include respond-to(md){
                height: 50px;    
            }
        }
    }
    .navbar-nav{
        text-align: center;
    }   
    .nav-item{
        padding: 0 15px;
    } 
    .nav-link{
        text-transform: uppercase;
        font-weight: 700;
        color: $black;
        &:hover{
            color: $green;
        }
    }
    .navbar-toggler{
        @include events{
            outline: 0;
        }
    }
    .fa-bars{
        font-size: 30px;
        color: $green;
    }
}
